import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { OpenPositionAdminService } from 'src/app/admin-portal/modules/open-position/services/open-position.service';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';
import { nameValidator } from 'src/app/utils/name-validation.utils';

@Component({
  selector: 'app-dialog-candidate',
  templateUrl: './candidate-dialog.component.html',
  styleUrl: './candidate-dialog.component.css',
})
export class CandidateDailogComponent {
  candidateForm!: FormGroup;
  isEditMode: boolean = false;
  isButtonDisabled: boolean = true;
  isSubmitting: boolean = false;
  isNumericInputValid: boolean = true;
  selectedFile: File | null = null;
  uploadedResume: string | undefined;
  getResume: string | undefined = '';
  resumeError: string | null = null;
  isDragOver: boolean = false;

  private unsubscribe: Subject<void> = new Subject();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    public matDialog: MatDialogRef<CandidateDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private candidateService: OpenPositionAdminService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.isEditMode = this.data.isEditMode;
    this.initializeForm();

    this.candidateForm.valueChanges.subscribe(() => {
      this.checkIfFormIsDirtyOrInvalid();
    });

    if (this.isEditMode && this.data.element) {
      this.patchFormValues(this.data.element);
    }

    this.checkIfFormIsDirtyOrInvalid();
  }

  patchFormValues(element: any): void {
    this.candidateForm.patchValue({
      candidateID: element.cd_candidateID,
      name: element.cd_name,
      email: element.cd_email,
      noticePeriod: element.cd_notice_period,
      resume: element.cd_resume ? element.cd_resume : '',
      positionID: this.data.op_positionID,
    });

    if (element.cd_resume) {
      this.uploadedResume = element.cd_resume;
      this.selectedFile = new File(
        [''],
        this.getFileNameFromUrl(element.cd_resume)
      );
    }
  }

  getFileNameFromUrl(url: string): string {
    return url.split('/').pop() || 'uploaded_resume';
  }

  initializeForm(): void {
    this.candidateForm = this.formBuilder.group({
      candidateID: [''],
      name: ['', [Validators.required, whitespaceValidator(), nameValidator]],
      email: ['', [Validators.email, Validators.required]],
      noticePeriod: ['', Validators.required],
      resume: ['', Validators.required],
      positionID: [this.data.positionID],
    });
  }

  openFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    this.handleFile(file);
  }

  handleFile(file: File | null): void {
    if (file) {
      const allowedTypes = ['application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        this.clearFileInput();
        this.resumeError = 'Invalid file type. Please select a PDF file.';
        return;
      }
      if (file.size > 10 * 1024 * 1024) {
        this.clearFileInput();
        this.resumeError = 'File size exceeds 10MB.';
        return;
      }
      this.selectedFile = file;
      this.resumeError = null;
      this.candidateForm.markAsDirty();
      this.uploadFileToServer(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    const file = event.dataTransfer?.files[0];
    this.handleFile(file || null);
  }

  clearFileInput(): void {
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  uploadFileToServer(file: File): void {
    if (file) {
      this.candidateService
        .uploadResume(file)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: (response: any) => {
            this.candidateForm.get('resume')?.setValue(response.id);
            this.resumeError = null;
            this.checkIfFormIsDirtyOrInvalid();
          },
          error: (error: any) => {
            console.error('Error uploading file:', error);
            this.resumeError = 'Error uploading file.';
          },
        });
    }
  }

  resetFileInput(): void {
    this.clearFileInput();
    this.selectedFile = null;
    this.candidateForm.patchValue({ resume: '' });
  }

  onSubmit(): void {
    if (this.candidateForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      const candidateData = this.candidateForm.value;

      if (this.isEditMode) {
        this.candidateService
          .updateCandidate(candidateData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => this.matDialog.close({ success: true }),
            error: (error: any) => console.log('error', error),
          });
      } else {
        this.candidateService
          .addCandidate(candidateData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => this.matDialog.close({ success: true }),
            error: (error: any) => console.log('error', error),
          });
      }
    }
  }

  checkIfFormIsDirtyOrInvalid(): void {
    this.isButtonDisabled =
      this.candidateForm.invalid || !this.candidateForm.dirty;
  }

  handleNumericInputValidityChange(validity: boolean) {
    this.isNumericInputValid = validity;
  }

  close() {
    this.matDialog.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
