<div
  class="modal-wrapper p-6 candidate-form-wrapper"
  [formGroup]="candidateForm"
>
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Candidate" : "Add Candidate" }}
      </h2>
      <p class="textXS mt-2 text">
        {{
          isEditMode
            ? "Enter details to edit candidate"
            : "Enter details to add candidate"
        }}
      </p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <input type="hidden" formControlName="positionID" />
  <input type="hidden" formControlName="candidateID" />
  <div class="content-wrapper my-4">
    <div class="col s12 m6 l6 mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Name <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="name"
        />
      </mat-form-field>
      <div
        *ngIf="
          candidateForm.get('name')?.touched || candidateForm.get('name')?.dirty
        "
      >
        <div
          *ngIf="candidateForm.get('name')?.hasError('whitespace')"
          class="required-star mt-1"
        >
          Field cannot be empty or contain only whitespace.
        </div>
        <div
          *ngIf="
            !candidateForm.get('name')?.hasError('whitespace') &&
            candidateForm.get('name')?.hasError('invalidNameSpecialChar')
          "
          class="required-star mt-1"
        >
          Field cannot contain special characters.
        </div>
        <div
          *ngIf="
            !candidateForm.get('name')?.hasError('whitespace') &&
            candidateForm.get('name')?.hasError('invalidName')
          "
          class="required-star mt-1"
        >
          Invalid Candidate Name.
        </div>
      </div>
    </div>
    <div class="col s12 m6 l6 mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Email Address <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input matInput placeholder="Enter here" formControlName="email" />
      </mat-form-field>
      <span
        *ngIf="
          candidateForm.get('email')?.invalid &&
          (candidateForm.get('email')?.touched ||
            candidateForm.get('email')?.dirty)
        "
        class="required-star"
        >Email is required and must be valid.</span
      >
    </div>
    <div class="col s12 m6 l6 mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Notice Period <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appNumericInput
          matInput
          maxlength="3"
          placeholder="Enter here"
          formControlName="noticePeriod"
          (numericInputValidityChange)="
            handleNumericInputValidityChange($event)
          "
        />
      </mat-form-field>
      <span class="required-star" *ngIf="!isNumericInputValid"
        >Please enter a numeric value.</span
      >
      <span
        class="required-star"
        *ngIf="
          candidateForm.get('noticePeriod')?.invalid &&
          (candidateForm.get('noticePeriod')?.touched ||
            candidateForm.get('noticePeriod')?.dirty) &&
          isNumericInputValid
        "
        >Field is required.</span
      >
    </div>
    <div class="col s12 m6 l6 mb-6">
      <p class="mat-body-1 text mb-1 input-label">
        Resume <span class="required-star">*</span>
      </p>
      <div
        cdkDropList
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        [ngClass]="{ 'drop-zone': true, 'drag-over': isDragOver }"
        class="drop-zone"
      >
        <input
          type="file"
          hidden
          (change)="onFileSelected($event)"
          accept=".pdf, image/*"
          #fileInput
        />
        <p class="drop-zone-placeholder" (click)="fileInput.click()">
          <img src="/images/upload.png" alt="" />
        </p>
        <p class="textS mb-4 text-black">Select a file or drag and drop here</p>
        <p class="textXS selected-pdf-info mb-4">
          PDF, file size no more than 10MB
        </p>
        <div *ngIf="!selectedFile && uploadedResume">
          <p>Uploaded file: {{ getFileNameFromUrl(uploadedResume) }}</p>
        </div>
        <div *ngIf="resumeError" class="error-message">
          <p>{{ resumeError }}</p>
        </div>
      </div>
      <mat-error *ngIf="resumeError" class="text-center textXS text-danger">
        {{ resumeError }}
      </mat-error>
      <span
        class="required-star"
        *ngIf="
          candidateForm.get('resume')?.invalid &&
          (candidateForm.get('resume')?.touched ||
            candidateForm.get('resume')?.dirty)
        "
      >
        Field is required.
      </span>
    </div>

    <div
      class="upload-resume-input d-flex justify-between"
      *ngIf="selectedFile"
    >
      <div>
        <p class="mat-body-1 info-text">{{ selectedFile.name }}</p>
      </div>
      <span class="material-symbols-outlined pointer"> publish </span>
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="isEditMode ? 'Update' : 'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (buttonClick)="onSubmit()"
    ></sft-button>
  </div>
</div>
